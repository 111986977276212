.bottom-Generate {
  display: flex;
  justify-content: center;
}
.export-pdf {
  margin-left: 10px !important;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  width: 200px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}
