body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font: 12pt 'Tahoma';
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.pageHoz {
  border: 1px #d3d3d3 solid;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 595px;
  height: 842px;
  padding-top: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.pageHoz1 {
  border: 1px #d3d3d3 solid;
  background: #29679e;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 842px;
  height: 595px;
  padding-top: 14mm;
  padding-bottom: 14mm;
  padding-left: 11mm;
  padding-right: 11mm;
}

.pageHoz2 {
  border: 1px #d3d3d3 solid;
  background: rgb(24, 158, 118);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 842px;
  height: 595px;
  padding-top: 14mm;
  padding-bottom: 14mm;
  padding-left: 11mm;
  padding-right: 11mm;
}

.itemCardCode {
  margin: 5px;
  position: relative;
}
.itemCardCode1 {
  position: relative;
}
.itemFontCard {
  margin: 5px;
}

.itemqr2 {
  left: 32.5px;
  top: 100px;
  position: absolute;
}
.itemqrfake {
  position: absolute;
  top: 40%;
  left: 24.5%;
  right: 25.5%;
  width: 50%;
}
canvas {
  width: 100% !important;
  height: 100% !important;
}
.centered1 {
  font-size: 13px;
  margin-left: 6%;
  margin-top: -5%;
}

.itemqr1 {
  /* font-family: "serif"; */
  margin-top: 20%;
  margin-left: 45%;
  font-size: 10px;
  color: rgb(96, 96, 96);
}

@page {
  size: A4 landscape;
  margin: 0;
}
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
.centered {
  position: absolute;
  top: 192px;
  left: 50px;
  font-size: 10px;
}
.itemqr5 {
  position: absolute;
  font-family: 'arial';
  top: 210px;
  left: 75px;
  font-size: 5px;
  color: rgb(96, 96, 96);
}
