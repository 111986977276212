.title-generator {
  font-size: 20px;
  font-weight: bold;
  color: #00c590;
}
.param-generator {
  font-size: 20px;
  margin-top: 10px;
}
.card-color {
  width: 150px;
  height: 20px;
  margin-top: 20px;
  margin-left: 20px;
  background-color: blue;
}
.bottom-Generate {
  display: flex;
  justify-content: center;
}
.gene {
  background-color: #ffffff;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  background-color: DodgerBlue;
}

.flex-container > div {
  background-color: #f1f1f1;
  width: 240px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}
